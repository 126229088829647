<template>
  <v-app>
    <v-main class="secondary px-0 pt-4">
      <Header />
      <Drawer />
      <v-container fluid >
          <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Drawer from "../components/Drawer.vue";
import Header from "../components/AppBar.vue";
export default {
  name: "Main",
  components: {
    Header,
    Drawer,
  },
};
</script>

<style scoped>
.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
