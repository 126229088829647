<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    class="primary"
    floating
    width="200"
  >
    <v-list color="transparent">
      <v-list-item style="justify-content: center">
        <v-list-item-avatar size="128" class="mr-0">
          <v-avatar v-if="user.avatarUrl" rounded size="172"
            ><img
              :src="user.avatarUrl"
              alt="avatar"
              style="object-fit: contain"
          /></v-avatar>
          <v-avatar
            v-else
            :color="user.gender == 'M' ? 'blue' : 'pink'"
            class="white--text"
            rounded
            size="172"
            tile
            ><span class="white--text text-h3"
              >{{ user.firstName !== null ? user.firstName[0] : ""
              }}{{ user.lastName !== null ? user.lastName[0] : "" }}</span
            ></v-avatar
          >
        </v-list-item-avatar>
      </v-list-item>
      <v-list-item>
        <v-list-item-title
          class="white--text text-center"
          style="font-size: 17px"
          >{{ user.firstName }} {{ user.lastName }}</v-list-item-title
        ></v-list-item
      >
      <v-divider />

      <template v-for="(item, i) in items">
        <v-list-item
          v-if="!item.spacer"
          :key="`tile-${i}`"
          :to="item.to"
          :value="item.value"
          color="grey"
          exact
          v-on="
            item.click && {
              click: item.click,
            }
          "
        >
          <v-list-item-avatar>
            <v-icon color="white" v-text="item.icon" />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="white--text" v-text="item.text" />
          </v-list-item-content>
        </v-list-item>

        <div v-else :key="`divider-${i}`" class="my-auto" />
      </template>
      <v-list-item style="justify-content: center" class="fixedBottom">
        <img
          :src="logo"
          alt="Logo images"
          style="height: 140px; widht: 140px; margin-bottom: 16px"
      /></v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapMutations, mapState } from "vuex";

export default {
  name: "CoreDrawer",
  data() {
    return {
      logo: require("../assets/images/logo/logo.svg"),
    };
  },
  computed: {
    drawer: {
      get() {
        return this.$store.state.app.drawer;
      },
      set(val) {
        this.setDrawer(val);
      },
    },
    items() {
      return [
        {
          icon: "mdi-timer-outline",
          text: "Rezultate",
          to: "/results",
        },
        {
          icon: "mdi-text-box-outline",
          text: "Notite",
          to: "/notes",
        },
        {
          icon: "mdi-account-key-outline",
          text: "Profil",
          to: "/profile",
        },
        {
          icon: "mdi-logout",
          text: "Logout",
          click: () => {
            this.$store.dispatch("authentication/logOff");
            this.$router.push({ name: "Landing" });
          },
        },
      ];
    },
    ...mapState("authentication", {
      auth_token: "auth_token",
      user: "user",
    }),
  },
  methods: {
    ...mapMutations("app", ["setDrawer"]),
  },
};
</script>

<style scoped>
.fixedBottom {
  position: fixed !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 100%;

  @media screen and (max-height: 800px) {
    display: none;
  }
}
</style>